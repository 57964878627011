import react, { useState, useEffect } from "react";
import Header from "../../component/header/header";
import Footer from "../../component/footer/footer";
import "./uniformCatgory.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useNavigate, useParams } from "react-router-dom";
const UniformCategory = () => {
  const [categoryName, setCategoryName] = useState("uniform");
  const [products, setProducts] = useState([]);
  const [image, setImage] = useState([]);
  const [category, setCategory] = useState([]);

  const navigate = useNavigate();
  const subCategory = useParams();
  console.log("subCategory", subCategory);
  const subCategoryName = subCategory.category;
  useEffect(() => {
    const categoryToFetch = subCategoryName || "School Uniforms";

    fetchProduct(categoryToFetch);
  }, [subCategoryName]);

  const fetchProduct = async (category) => {
    try {
      const response = await fetch(
        `https://unipride-website-9xdk.onrender.com/api/products/subcategory/${category}`
      );
      if (response.ok) {
        const data = await response.json();
        console.log(data?.image);
        setImage(data?.image);
        setProducts(data.products);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const singleProductHandler = (id, title) => {
    // navigate("/product/" + id);
    const formattedTitle = title.replace(/ /g, "_");
    console.log(formattedTitle);
    navigate("/product/" + formattedTitle + "/" + id);
  };

  const categoryImage = image.toString();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(
          `https://unipride-website-9xdk.onrender.com/api/categories/${categoryName}/subcategories`
        );
        if (response.ok) {
          const data = await response.json();
          if (data) {
            setCategory(data);
          } else {
          }
        } else {
          throw new Error("Product not found");
        }
      } catch (error) {}
    };
    fetchProduct();
  }, [categoryName]);

  return (
    <div className="uniform-category-main">
      <div class="icon-bar">
        <a href=" https://wa.me/+918815190040" class="whatsapp">
          <i class="bi bi-whatsapp"></i>
        </a>
      </div>
      <div className="category-hero-img-section">
        <Header />
        <div className="category-main-hero-content-section">
          <img
            src={image}
            alt="image-category"
            className="category-hero-img-section"
          />
        </div>
      </div>

      {products.map((product, index) => {
        const capitalizedTitle =
          product.title.charAt(0).toUpperCase() + product.title.slice(1);

        if (index % 3 == 0)
          return (
            <div className="category-card-outer row" key={product._id}>
              <div className="category-card-inner row col-md-12">
                <div className="category-card-section-1 col-md-6">
                  <div
                    className="category-card-big row"
                    onClick={() => {
                      singleProductHandler(
                        product?._id,
                        products[index + 1]?.title
                      );
                    }}
                  >
                    <div className="card-image col-md-6">
                      <div className="card-image-outer">
                        <div className="card-color">
                          {products[index]?.colors.map((color, colorIndex) => {
                            return (
                              <div
                                key={colorIndex}
                                className="color-outer col-md-4"
                              >
                                <div
                                  className="color1"
                                  style={{
                                    backgroundColor: color,
                                  }}
                                ></div>
                              </div>
                            );
                          })}
                        </div>
                        <div className="card-image-just-outer">
                          <img src={product?.frontImage} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="card-about col-md-6">
                      <div className="card-about-outer">
                        <h4>{capitalizedTitle}</h4>
                        {product?.shortDescription.length > 150 ? (
                          <p>
                            {product?.shortDescription.substring(0, 150)}
                            ...
                          </p>
                        ) : (
                          <p>{product?.shortDescription}</p>
                        )}
                        <div className="submit-btn">
                          <button className="view-more-btn">View More</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="category-card-section-2 col-md-6">
                  <div className="category-card-section-2-outer row">
                    {products[index + 1] && (
                      <div
                        className="category-card-small-1 col-md-12"
                        onClick={() => {
                          singleProductHandler(
                            products[index + 1]?._id,
                            products[index + 1].title
                          );
                        }}
                      >
                        <div className="category-card-small-1-inner row">
                          <div className="small-image-1 col-md-5">
                            <div className="card-color">
                              {products[index + 1]?.colors.map(
                                (color, colorIndex) => {
                                  return (
                                    <div
                                      key={colorIndex}
                                      className="color-outer col-md-4"
                                    >
                                      <div
                                        className="color1"
                                        style={{
                                          backgroundColor: color,
                                        }}
                                      ></div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                            <img src={products[index + 1].frontImage} alt="" />
                          </div>
                          <div className="small-about-1 col-md-7">
                            <div className="small-about-width-1">
                              <h4>
                                {products[index + 1].title
                                  .charAt(0)
                                  .toUpperCase() +
                                  products[index + 1].title.slice(1)}
                              </h4>
                              {products[index + 1]?.shortDescription.length >
                              100 ? (
                                <p>
                                  {products[
                                    index + 1
                                  ].shortDescription.substring(0, 100)}
                                  ...
                                </p>
                              ) : (
                                <p>{products[index + 1].shortDescription}</p>
                              )}{" "}
                              <div className="submit-btn">
                                <button className="view-more-btn">
                                  View More
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {products[index + 2] && (
                      <div
                        className="category-card-small-2 col-md-12"
                        onClick={() => {
                          singleProductHandler(
                            products[index + 2]?._id,
                            products[index + 1].title
                          );
                        }}
                      >
                        <div className="category-card-small-2-inner row">
                          <div className="small-image-2 col-md-5">
                            <div className="card-color">
                              {products[index + 2]?.colors.map(
                                (color, colorIndex) => {
                                  return (
                                    <div
                                      key={colorIndex}
                                      className="color-outer col-md-4"
                                    >
                                      <div
                                        className="color1"
                                        style={{
                                          backgroundColor: color,
                                        }}
                                      ></div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                            <img src={products[index + 2].frontImage} alt="" />
                          </div>
                          <div className="small-about-2 col-md-7">
                            <div className="small-about-width-2">
                              <h4>
                                {products[index + 2].title
                                  .charAt(0)
                                  .toUpperCase() +
                                  products[index + 2].title.slice(1)}
                              </h4>

                              {products[index + 2]?.shortDescription.length >
                              100 ? (
                                <p>
                                  {products[
                                    index + 2
                                  ].shortDescription.substring(0, 100)}
                                  ...
                                </p>
                              ) : (
                                <p>{products[index + 2].shortDescription}</p>
                              )}
                              <div className="submit-btn">
                                <button className="view-more-btn">
                                  View More
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
      })}

      <div className="subcategories">
        <div className="heading-categories">
          <h1 className="sub-heading">Sub-Category</h1>
        </div>
        <div className="carousal-div">
          <OwlCarousel
            key={category?.length}
            className="owl-theme"
            loop
            margin={10}
            // autoplay="true"
            nav={true}
            responsive={{
              0: {
                items: 1,
              },
              600: {
                items: 2,
              },
              800: {
                items: 3,
              },
              1000: {
                items: 4,
              },
            }}
          >
            <div className="div">
              <div
                className="category-card"
                onClick={() => {
                  navigate("/uniformCategory/School%20Uniforms");
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <h4 className="title">School Uniforms</h4>
              </div>
            </div>
            <div className="div">
              <div
                className="category-card"
                onClick={() => {
                  navigate("/uniformCategory/Industrial%20Uniforms");
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <h4 className="title">Industrial Uniforms</h4>
              </div>
            </div>
            <div className="div">
              <div
                className="category-card"
                onClick={() => {
                  navigate("/uniformCategory/Hospital%20Linens%20&%20Linens");
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <h4 className="title">Hospital Linens & Linens</h4>
              </div>
            </div>
            <div className="div">
              <div
                className="category-card"
                onClick={() => {
                  navigate("/uniformCategory/Hotel%20Uniforms%20&%20Linens");
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <h4 className="title">Hotel Uniforms & Linens</h4>
              </div>
            </div>
            <div className="div">
              <div
                className="category-card"
                onClick={() => {
                  navigate("/uniformCategory/Pharmaceutical%20Uniforms");
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <h4 className="title">Pharmaceutical Uniforms</h4>
              </div>
            </div>
            <div className="div">
              <div
                className="category-card"
                onClick={() => {
                  navigate("/uniformCategory/Housekeeping%20Uniforms");
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <h4 className="title">Housekeeping Uniforms</h4>
              </div>
            </div>
            <div className="div">
              <div
                className="category-card"
                onClick={() => {
                  navigate("/uniformCategory/Security%20Uniform");
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <h4 className="title">Security Uniforms</h4>
              </div>
            </div>
            <div className="div">
              <div
                className="category-card"
                onClick={() => {
                  navigate("/uniformCategory/Corporate%20Wear");
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <h4 className="title">Corporate Wear</h4>
              </div>
            </div>
            <div className="div">
              <div
                className="category-card"
                onClick={() => {
                  navigate("/uniformCategory/Promotional%20Wear");
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <h4 className="title">Promotional Wear</h4>
              </div>
            </div>
            <div className="div">
              <div
                className="category-card"
                onClick={() => {
                  navigate("/cooperateGifting");
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <h4 className="title">Cooperate Gifting</h4>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default UniformCategory;
