import react, { useState } from "react";
import "./contact.scss";
import Header from "../../component/header/header";
import Footer from "../../component/footer/footer";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const Contact = () => {
  const navigate = useNavigate();
  const [inputHandler, setInputHandler] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
  });

  const onChangeInputHandler = (event) => {
    console.log(event.target.value);
    setInputHandler({
      ...inputHandler,
      [event.target.name]: event.target.value,
    });
  };

  const submitHandler = () => {
    const data = axios
      .post(
        `https://unipride-website-9xdk.onrender.com/api/createContact`,
        inputHandler
      )
      .then((response) => {
        console.log(response);
        toast.success("Response Send Successfully", {
          position: "bottom-right",
          autoClose: 8000,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
        navigate("/ThankYou");
        setInputHandler({
          name: "",
          email: "",
          number: "",
          message: "",
        });
      })
      .catch((error) => {
        console.log(error);
      });

    console.log(data);
  };
  return (
    <div>
      <div className="contact-main">
        <Helmet>
          <title>Unipride Business Uniform | Contact Us</title>
          <meta
            name="description"
            content="If you have any queries related to customised t-shirts and uniforms please message us here and we will answer all your queries. Contact us now!"
          />
        </Helmet>
        <div className="contact-hero-img-section">
          <Header />
          <div className="contact-main-hero-content-section">
            <h1 className="contact-content">Contact Us</h1>
          </div>
        </div>
        <div className="contact-submain row">
          <div className="lets-talk-outer col-12">
            <div className="lets-talk-inner">
              <div className="lets-talk-text">
                <h1>Let's Talk!</h1>
                <p>
                  Get in touch with us using the enquiry form or contact details
                  below.
                </p>
              </div>
              <div className="lets-talk-form">
                <div className="lets-talk-form-inner row">
                  <div className="name col-4">
                    <h6>Name</h6>
                    <input
                      type="text"
                      name="name"
                      value={inputHandler.name}
                      onChange={onChangeInputHandler}
                      placeholder="Name"
                    />
                  </div>
                  <div className="name col-4">
                    <h6>Email</h6>
                    <input
                      type="text"
                      name="email"
                      value={inputHandler.email}
                      onChange={onChangeInputHandler}
                      placeholder="Email"
                    />
                  </div>
                  <div className="name col-4">
                    <h6>Number</h6>
                    <input
                      type="tel"
                      name="number"
                      value={inputHandler.number}
                      onChange={onChangeInputHandler}
                      placeholder="Number"
                    />
                  </div>
                  <div className="name col-12">
                    <h6>Message</h6>
                    <textarea
                      type="text"
                      name="message"
                      value={inputHandler.message}
                      onChange={onChangeInputHandler}
                      placeholder="Message"
                    />
                  </div>
                  <div className="send-button" onClick={submitHandler}>
                    <button>Send</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-side col-12">
            <div className="contact-side-outer row">
              <div className="location d-flex flex-row  col-sm-6 col-12">
                <i class="bi bi-geo-alt"></i>
                <div className="location-text">
                  <h5>Our Location</h5>
                  <p>
                    309/1/2 Gayatri Nagar Tejaji Chowk, Palda, Indore 452010
                  </p>
                </div>
              </div>
              <div className="location d-flex flex-row  col-sm-6 col-12">
                <i class="bi bi-envelope"></i>
                <div className="location-text">
                  <h5>Quick Contact</h5>
                  <p>
                    <span>Email: </span>
                    <Link to="mailto:sales@unipride.in">sales@unipride.in</Link>
                  </p>
                </div>
              </div>
              <div className="location d-flex flex-row  col-sm-6 col-12">
                <i class="bi bi-telephone"></i>
                <div className="location-text">
                  <h5>Phone Number</h5>
                  <Link to="tel:+918815190040">+91 88151 90040</Link>
                </div>
              </div>
              <div className="follow-us  col-sm-6 col-12">
                <h4>Follow Us</h4>
                <div className="logo">
                  <div className="logo-in">
                    <Link to="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFTG05Jpv2N-gAAAY6dvzo4HHbj-2Op_-Ml7STg3ZaraFxl4ZrduqmjBr1j4ron3zQstT3lFCPZ4FqFi-m7zVUctQEFCJ01uTDdnxoak7_DwcvkxrYv1UTiPj2G3cfJ52jihG4=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Funipride%2F">
                      <i class="bi bi-linkedin"></i>
                    </Link>
                  </div>
                  <div className="logo-in">
                    <Link to="https://www.instagram.com/uniprideworkwear/">
                      <i class="bi bi-instagram"></i>
                    </Link>
                  </div>
                  <div className="logo-in">
                    <Link to="https://www.facebook.com/profile.php?viewas=100000686899395&id=61557394060704">
                      <i class="bi bi-facebook"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </div>
  );
};

export default Contact;
