import React, { useState, useEffect } from "react";
// import "../CreateBlog/createBlog";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Header from "../../component/header/header";
import Footer from "../../component/footer/footer";
import "./editBlog.scss";
const EditBlog = () => {
  const history = useNavigate();
  const [image, setImage] = useState();
  const { id } = useParams();
  const [inputHandler, setInputHandler] = useState({
    id: id,
    title: "",
    content: "",
    keyword: "",
  });

  const getOneBlog = async () => {
    try {
      const { data } = await axios.get(
        "https://unipride-website-9xdk.onrender.com/blog/blog/" + id
      );
      // console.log(data);
      setInputHandler({
        title: data.data.title,
        content: data.data.content,
        keyword: data.data.keyword,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token) {
    } else {
      history("/login");
    }
  }, []);

  useEffect(() => {
    getOneBlog();
  }, []);

  useEffect(() => {
    axios
      .put("https://unipride-website-9xdk.onrender.com/blog/updateBlog/" + id)
      .then((res) => {
        // console.log(res.data);
        setInputHandler({
          ...inputHandler,
          title: res.data.updatedBlog.title,
          content: res.data.updatedBlog.content,
          keyword: res.data.updatedBlog.keyword,
          image: res.data.updatedBlog.image,
          EditBlog,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const backToDashboard = () => {
    history("/dashboard");
  };

  const onChangeInputHandler = (e) => {
    const { name, value } = e.target;
    setInputHandler(() => {
      return { ...inputHandler, [name]: value };
    });
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    // Check if a new image is provided
    if (image) {
      formData.append("image", image);
    }

    try {
      // Attempt to upload the image if a new one is provided
      let imageUrl = null;
      if (image) {
        imageUrl = await axios.post(
          "https://unipride-website-9xdk.onrender.com/blog/uploadBlogImage",
          formData
        );
      }

      // Prepare the data for the update request
      const updateData = {
        title: inputHandler.title,
        content: inputHandler.content,
        keyword: inputHandler.keyword,
        // Only include the image URL if a new image was uploaded and the upload was successful
        ...(imageUrl && imageUrl.data.success && { image: imageUrl.data.url }),
      };

      // Update the blog
      await axios.put(
        `https://unipride-website-9xdk.onrender.com/blog/updateBlog/${id}`,
        updateData
      );
      history("/dashboard");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="editBlog">
        <Header />
        <section>
          <div className="form_data">
            <div
              className="cross d-flex align-item-center flex-row"
              onClick={backToDashboard}
            >
              <div className="cross-inner">
                <i class="bi bi-arrow-left"></i>
              </div>
              <h4>Back</h4>
            </div>
            <div className="form_heading">
              <h1>Update Resource Center</h1>
            </div>

            <form>
              <div className="form_input">
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  onChange={onChangeInputHandler}
                  id="title"
                  name="title"
                  value={inputHandler.title}
                  placeholder="Title"
                />
              </div>
              <div className="form_input">
                <label htmlFor="title">Content</label>
                <textarea
                  style={{ width: "100%" }}
                  rows={10}
                  type="text"
                  onChange={onChangeInputHandler}
                  value={inputHandler.content}
                  id="content"
                  name="content"
                  placeholder="Content ...."
                />
              </div>
              {/* <div className="form_input">
              <label htmlFor="readingTime"> Estimate Reading Time</label>
              <input
                type="readingTime"
                onChange={onChangeInputHandler}
                value={inputHandler.readingTime}
                id="readingTime"
                name="readingTime"
                placeholder="readingTime"
              />
            </div> */}
              <div className="form_input">
                <label for="productImage">Resource Image</label>
                <input
                  type="file"
                  id="productImage"
                  // value={}
                  onChange={(e) => setImage(e.target.files[0])}
                  name="image"
                  accept="image/*"
                  required
                />
              </div>
              <button className="btn" onClick={onSubmitHandler}>
                Update Resource Center
              </button>
            </form>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default EditBlog;
