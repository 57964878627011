import React from "react";
import "./PrivacyPolicy.scss";
import Header from "../../component/header/header";
import Footer from "../../component/footer/footer";

function PrivacyPolicy() {
  return (
    <>
      <Header />
      <div className="OuterDOPri">
        <div className="justDo">
          <h2 className="mx-4">
            {" "}
            Use of Unipride’s website and services is governed by these terms
            and conditions.
          </h2>

          <ul className="Para">
            <li className="list">
              All content on this site is owned by Unipride or its licensors.
              You may only use it for personal purposes unless you have prior
              consent.
            </li>
            <li className="list">
              Do not republish material without permission.
            </li>
            <li className="list">
              Do not sell, sublicense, or commercialize any website content.
            </li>
            <li className="list">
              Do not use the website in a way that harms it or restricts access
              to it.
            </li>
            <li className="list">
              Do not use the website unlawfully or for data mining.
            </li>
            <li className="list">
              By displaying content on this site, you grant Unipride a license
              to use, reproduce, and distribute it.
            </li>
            <li className="list">
              Unipride is not liable for any indirect, consequential, or special
              damages arising from the use of this website.
            </li>
            <li className="list">
              You agree to indemnify Unipride against any liabilities arising
              from your breach of these terms.
            </li>
            <li className="list">
              If any term is found invalid, it does not affect the remaining
              terms.
            </li>
            <li className="list">
              Unipride may revise these terms at any time. Check regularly for
              updates.
            </li>
            <li className="list">
              {" "}
              Unipride can assign its rights and obligations without notice. You
              cannot assign your rights and obligations.
            </li>
            <li className="list">
              These terms replace all prior agreements and understandings.
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
