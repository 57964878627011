import react, { useState } from "react";
import AboutHeroImg from "../../assets/aboutHero.png";
import AboutPeopleImg from "../../assets/whoweare.jpg";
import visionImg from "../../assets/VISION.png";
import missionImg from "../../assets/mission.png";
import Header from "../../component/header/header";
import Footer from "../../component/footer/footer";
import "./about.scss";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS
import { Helmet } from "react-helmet";

function About() {
  const [inputHandler, setInputHandler] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
  });

  const onChangeInputHandler = (event) => {
    console.log(event.target.value);
    setInputHandler({
      ...inputHandler,
      [event.target.name]: event.target.value,
    });
  };

  const submitHandler = () => {
    const data = axios
      .post(
        `https://unipride-website-9xdk.onrender.com/api/createContact`,
        inputHandler
      )
      .then((response) => {
        console.log(response);
        toast.success("Response Send Successfully", {
          position: "bottom-right",
          autoClose: 8000,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
        setInputHandler({
          name: "",
          email: "",
          number: "",
          message: "",
        });
      })
      .catch((error) => {
        console.log(error);
      });

    console.log(data);
  };
  return (
    <div className="about-wrapper">
      <Helmet>
        <title>Business T Shirts Design and Work Wear T Shirts </title>
        <meta
          name="description"
          content="Unipride, leading manufacturer of high-quality uniforms. Our focus is make innovation and provides customer satisfaction in the area of professional attire."
        />
        <meta
          name="keywords"
          content="business t shirt design, tee shirts with logos, it t shirt"
        />
      </Helmet>
      <div className="about-hero-img-section">
        <div className="overlay"></div>
        <Header />
        <div className="about-main-hero-content-section">
          <h1 className="about-content">About Us</h1>
        </div>
      </div>
      <div className="about-wrapper-section-part-2">
        <div className=" inner-container row">
          <div className="row col-md-12">
            <div className="left-section-wrapper col-md-6 ">
              <div className="colgapping">
                {/* <div className="left-section-wrapper-content">
                <h3 className="left-section-content">
                  <h2 style={{ fontSize: "50px", fontWeight: 700 }}>Who</h2> we
                  are :
                </h3>
              </div> */}
                <div className="left-section-wrapper-paragraph">
                  <p className="left-section-paracontent">
                    We Unipride, are a leading manufacturer of high-quality
                    uniforms and workwear. With a commitment to excellence, we
                    specialise in crafting durable and stylish attire tailored
                    to meet the specific needs of various industries. Our focus
                    on innovation and customer satisfaction sets us apart in the
                    realm of professional attire.
                  </p>
                </div>{" "}
              </div>
            </div>
            <div className="right-top-section col-md-6 ">
              <div className="colgapping">
                <div className="right-section-img col-md-12">
                  <img
                    src={AboutPeopleImg}
                    className="right-section-people-img"
                    alt="unipride uniforms"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="right-section-wrapper col-md-12">
            <div className="right-section-lower-wrapper row">
              <div className="col-md-4">
                <div className="right-subsection-part2 col-md-12">
                  <div className="right-subsection-total-review">
                    <h3 className="subsection-value">Endless</h3>
                  </div>
                  <div className="right-subsection-positive-review">
                    <span className="subsection-positive">Customisations</span>
                  </div>
                  <div className="right-subsection-para-data">
                    <p className="subsection-para-data">
                      Get workwear and uniforms that are as unique as you are.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="section-subsection-content">
                  <div className="right-subsection col-12">
                    <div className="subsection-number-wrapper">
                      <h3 className="subsection-exp">One</h3>
                    </div>
                    <div className="subsection-content-wrapper">
                      <span className="subsection-content">Stop Shop</span>
                    </div>
                    <div className="subsection-para-wrapper">
                      <p className="subsection-para">
                        Comprehensive range of high-quality products bringing
                        customisable outfits for your team with our expertise.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="right-right-about">
                  <div className="right-right-section-wrapper ">
                    <div className="right-section-client">
                      <h3 className="right-count-client">11 years</h3>
                    </div>
                    <div className="right-subsection-trusted">
                      <span className="stop-shop">Delivering Excellence</span>
                    </div>
                    <div className="right-subsection-paragraphContent">
                      <p className="right-para-content">
                        Delivering top-notch workwear that sets you ahead of the
                        curve for more than a decade.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-section-container-process">
        <div className="process-focus-section">
          <div className="process-focus-container">
            <h2 className="process-focus-heading">
              Process focussing perfection
            </h2>
          </div>
          <div className="process-para-container">
            <p className="process-paragraph">
              At Unipride, perfection is our priority throughout our meticulous
              process. From design conception to final product, we uphold
              stringent standards to ensure unparalleled quality. Each step is
              meticulously executed, from fabric selection to stitching detail,
              guaranteeing excellence in every garment. Our commitment to
              perfection is unwavering, ensuring that your workwear and uniforms
              not only meet but exceed expectations, reflecting our dedication
              to craftsmanship and customer satisfaction. Trust us to deliver
              nothing short of perfection, every time.
            </p>
          </div>
        </div>

        <div className="about-grid-container row">
          <div className="row grid-wrapper-1">
            <div className=" col-md-6 grid-section ">
              <div className="grid-section-1">
                {/* <div className="vision-title">
                  <h2 className="vision-content">Our Vision</h2>
                </div> */}
                <div className="vision-para-container">
                  <p className="vision-para">
                    We aim to redefine professionalism through innovative
                    workwear solutions, tailored to elevate both comfort and
                    style. We aim to inspire confidence and excellence in every
                    workplace, educational institution and corporates we serve.
                  </p>
                </div>
              </div>
            </div>
            <div className="grid-section-2 col-md-6">
              <div className="grid-right-img">
                <img
                  className="vision-img"
                  src={visionImg}
                  heading
                  alt="Quality Workwear and Uniforms"
                />
              </div>
            </div>
          </div>
          <div className="row grid-wrapper-2">
            <div className="grid-section-3 col-md-6">
              <div className="grid-right-img1">
                <img
                  className="mission-img"
                  src={missionImg}
                  heading
                  alt="Innovative Workwear"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="grid-section-4 ">
                {/* <div className="mission-title">
                  <h2 className="mission-content">Our Mission</h2>
                </div> */}
                <div className="mission-para-container">
                  <p className="mission-para">
                    Our commitment is to provide exceptional workwear and school
                    uniforms, tailored to individual needs, with a focus on
                    quality, innovation, and customer satisfaction, setting the
                    standard for professional attire.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact">
        <div className="container-main d-flex align-items-center row">
          <div className="text col-md-7">
            {/* <div className="circle"></div> */}
            <h2>Looking for Something New</h2>
          </div>
          <div className="contact-form col-md-5">
            <div className="name ">
              {/* <h6>Name</h6> */}
              <input
                type="text"
                name="name"
                className="userName"
                value={inputHandler.name}
                onChange={onChangeInputHandler}
                placeholder="Name"
              />
            </div>
            <div className="name ">
              {/* <h6>Email</h6> */}
              <input
                type="text"
                name="email"
                className="userName"
                value={inputHandler.email}
                onChange={onChangeInputHandler}
                placeholder="Email"
              />
            </div>
            <div className="name col-4">
              {/* <h6>Number</h6> */}
              <input
                type="text"
                name="number"
                className="userName"
                value={inputHandler.number}
                onChange={onChangeInputHandler}
                placeholder="Number"
              />
            </div>
            <div className="name ">
              {/* <h6>Message</h6> */}
              <textarea
                type="text"
                name="message"
                className="userName"
                value={inputHandler.message}
                onChange={onChangeInputHandler}
                placeholder="Message"
              />
            </div>
            <div className="button" onClick={submitHandler}>
              <button className="learn-more">Send Message</button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </div>
  );
}

export default About;
