import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./createProduct.scss";
import Header from "../../component/header/header";
import Footer from "../../component/footer/footer";

const CreateProduct = () => {
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [inputHandler, setInputHandler] = useState({
    title: " ",
    shortDescription: " ",
    description: " ",
    categoryName: "",
    subCategoryName: "",
  });
  const [image, setImage] = useState();
  const [backImage, setBackImage] = useState();
  const [backImg, setBackImg] = useState([]);

  const navigate = useNavigate();
  const [tags, setTags] = useState([]);
  const [img, setImg] = useState([]);

  const onChangeInputHandler = (e) => {
    const { name, value } = e.target;
    setInputHandler(() => {
      return { ...inputHandler, [name]: value };
    });
  };
  const history = useNavigate();

  const backToDashBoard = () => {
    history("/dashboard");
  };
  const addTags = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      setTags([...tags, event.target.value]);
      event.target.value = "";
    }
  };

  const addImg = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      setImg([...img, event.target.value]);
      event.target.value = "";
    }
  };

  const addBackImg = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      setBackImg([...img, event.target.value]);
      event.target.value = "";
    }
  };

  const removeBackImg = (index) => {
    setBackImg([...tags.filter((img) => img.indexOf(img) !== index)]);
  };

  const removeImg = (index) => {
    setImg([...tags.filter((img) => img.indexOf(img) !== index)]);
  };

  const removeTags = (index) => {
    setTags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          "https://unipride-website-9xdk.onrender.com/api/getCategory"
        );
        console.log(response.data);
        setCategories(response.data);
      } catch (error) {
        console.error("Failed to fetch categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token) {
    } else {
      history("/login");
    }
  }, []);

  const handleCategoryChange = (e) => {
    const selectedCategory = categories.find(
      (category) => category.name === e.target.value
    );
    setSubcategories(selectedCategory.subcategories);
    setInputHandler({ ...inputHandler, categoryName: e.target.value });
  };

  const handleSubCategoryChange = (e) => {
    setInputHandler({ ...inputHandler, subCategoryName: e.target.value });
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    const {
      title,
      shortDescription,
      description,
      categoryName,
      subCategoryName,
    } = inputHandler;
    if (
      !title ||
      !shortDescription ||
      !description ||
      !image ||
      !categoryName ||
      !subCategoryName
    ) {
      alert("Please fill in all required fields");
      return;
    }

    const formData = new FormData();
    formData.append("image", image);
    const backFormData = new FormData();
    backFormData.append("backImage", backImage);

    try {
      const imageUrlResponse = await axios.post(
        "https://unipride-website-9xdk.onrender.com/api/upload",
        formData
      );
      const backImageUrlResponse = await axios.post(
        "https://unipride-website-9xdk.onrender.com/api/BackImageUpload",
        backFormData
      );

      if (imageUrlResponse.data.success && backImageUrlResponse.data.success) {
        const productData = {
          title,
          shortDescription,
          description,
          frontImage: imageUrlResponse.data.url,
          backImage: backImageUrlResponse.data.url,
          categoryName,
          subCategoryName,
          colors: tags,
        };

        const productResponse = await axios.post(
          "https://unipride-website-iwf0.onrender.comapi/createProduct",
          productData
        );
        if (productResponse.data.success) {
          alert("Product Added Successfully");
          navigate("/dashboard");
          setInputHandler({
            title: "",
            shortDescription: "",
            description: "",
            categoryName: "",
            subCategoryName: "",
          });
          setImage(undefined);
          setTags([]);
          setBackImg([]);
        }
      }
    } catch (error) {
      console.error("Error creating product:", error);
    }
  };

  return (
    <>
      <div className="createproduct">
        <Header />

        <section>
          {
            <div className="form_data">
              <div className="cross" onClick={backToDashBoard}>
                <div className="cross-inner">
                  <i class="bi bi-arrow-left"></i>
                </div>
                <h4>Back</h4>
              </div>
              <div className="form_heading">
                <h1>Create Product</h1>
              </div>

              <form>
                <div className="form_input">
                  <label htmlFor="title">Title</label>
                  <input
                    type="text"
                    onChange={onChangeInputHandler}
                    value={inputHandler.title}
                    id="title"
                    name="title"
                    placeholder="Title"
                  />
                </div>
                <div className="form_input">
                  <label htmlFor="shortDescription">Short description</label>
                  <textarea
                    style={{ width: "100%" }}
                    rows={3}
                    type="text"
                    onChange={onChangeInputHandler}
                    value={inputHandler.shortDescription}
                    id="shortDescription"
                    name="shortDescription"
                    placeholder=" A short Description ...."
                  />
                </div>

                <div className="form_input">
                  <label htmlFor="description">Description</label>
                  <textarea
                    style={{ width: "100%" }}
                    rows={10}
                    type="description"
                    onChange={onChangeInputHandler}
                    value={inputHandler.description}
                    id="description"
                    name="description"
                    placeholder="description... "
                  />
                </div>
                <div className="form_input">
                  <label htmlFor="categoryName">Category Name</label>
                  <select
                    className="categoryName"
                    id="categoryName"
                    name="categoryName"
                    value={inputHandler.categoryName}
                    onChange={handleCategoryChange}
                  >
                    <option value="">Select a category</option>
                    {categories.map((category, index) => (
                      <option key={index} value={category.name}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form_input">
                  <label htmlFor="subCategoryName">Sub Category Name</label>
                  <select
                    className="categoryName"
                    id="subCategoryName"
                    name="subCategoryName"
                    value={inputHandler.subCategoryName}
                    onChange={handleSubCategoryChange}
                  >
                    <option value="">Select a subcategory</option>
                    {subcategories.map((subcategory, index) => (
                      <option key={index} value={subcategory.name}>
                        {subcategory.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form_input">
                  <label for="productImage">Front Image</label>

                  <div className="tags-input-container pt-2 cursor-pointer">
                    <input
                      type="file"
                      id="image"
                      onKeyDown={(event) => addImg(event)}
                      onChange={(e) => setImage(e.target.files[0])}
                      name="image"
                      className="tags-input w-100 p-2"
                      multiple
                      accept="image/*"
                      required
                    />
                    {img.map((img, index) => (
                      <span className="tag-item p-2 cursor-pointer" key={index}>
                        <span className="text">{img}</span>
                        <span
                          className="close"
                          onClick={() => removeImg(index)}
                        >
                          &times;
                        </span>
                      </span>
                    ))}
                  </div>
                </div>
                <div className="form_input">
                  <label for="productImage">Back Image</label>

                  <div className="tags-input-container pt-2 cursor-pointer">
                    <input
                      type="file"
                      id="backImage"
                      onKeyDown={(event) => addBackImg(event)}
                      onChange={(e) => setBackImage(e.target.files[0])}
                      name="backImage"
                      accept="image/*"
                      className="tags-input w-100 p-2"
                      required
                    />
                    {backImg.map((img, index) => (
                      <span className="tag-item p-2 cursor-pointer" key={index}>
                        <span className="text">{backImg}</span>
                        <span
                          className="close"
                          onClick={() => removeBackImg(index)}
                        >
                          &times;
                        </span>
                      </span>
                    ))}
                  </div>
                </div>
                <div className="tags">
                  <label>Colors</label>
                  <div className="tags-input-container pt-2 cursor-pointer">
                    <input
                      onKeyDown={(event) => addTags(event)}
                      type="text"
                      className="tags-input w-100 p-2"
                      placeholder="Please enter your tags here"
                    />
                    {tags.map((tag, index) => (
                      <span className="tag-item p-2 cursor-pointer" key={index}>
                        <span className="text">{tag}</span>
                        <span
                          className="close"
                          onClick={() => removeTags(index)}
                        >
                          &times;
                        </span>
                      </span>
                    ))}
                  </div>
                </div>
                <div className="btn" onClick={onSubmitHandler}>
                  Create Product
                </div>
              </form>
            </div>
          }
        </section>
        <Footer />
      </div>
    </>
  );
};

export default CreateProduct;
