import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./ThanksYou.scss"; // We'll define the styles here

function ThanksYou() {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/contact");
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="thank-you-container">
      <div className="thank-you-message">
        <div className="line">Thank You</div>
        <div className="line">For Contacting Us</div>
      </div>
    </div>
  );
}

export default ThanksYou;
