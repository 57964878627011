import React from "react";
import "./giftingCard.scss";
import { useNavigate } from "react-router-dom";
import img1 from "../../assets/Group 135.png";
import img2 from "../../assets/Vector.png";
import img3 from "../../assets/Group.png";
import img4 from "../../assets/Group (1).png";
import img from "../../assets/fire.png";
const GiftingCard = ({ item, index }) => {
  const navigate = useNavigate();
  const singleProductHandler = (id, title) => {
    const formattedTitle = title.replace(/ /g, "_");
    console.log(formattedTitle);
    navigate("/product/" + formattedTitle + "/" + id);

    window.scrollTo(0, 0);
  };

  return (
    <div className="giftMain">
      <div
        className="giftingCard"
        onClick={() => {
          singleProductHandler(item?._id, item?.title);
        }}
      >
        <div className="giftImage">
          <img src={item?.frontImage} alt={item?.title} />
        </div>
        <div className="giftText">
          <div className="giftName">
            <h5>{item.title}</h5>
          </div>
          <div className="wrapper-div">
            <div className="inner-wrapper">
              <div className="wrapper">
                <div className="img-outer">
                  <button
                    type="button"
                    class="btn"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Utilities"
                  >
                    <img src={img1} alt="" />
                  </button>
                </div>
              </div>
              <div className="wrapper">
                <div className="img-outer">
                  <button
                    type="button"
                    class="btn"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Construction"
                  >
                    <img src={img2} alt="" />
                  </button>
                </div>
              </div>
              <div className="wrapper">
                <div className="img-outer">
                  <button
                    type="button"
                    class="btn"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Oil and Gases"
                  >
                    <img src={img3} alt="" />
                  </button>
                </div>
              </div>
              <div className="wrapper">
                <div className="img-outer">
                  <button
                    type="button"
                    class="btn"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Fire Fighting"
                  >
                    <img src={img4} alt="" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="submit-btn">
            <button className="view-more-btn">Know More</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftingCard;
