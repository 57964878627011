import React, { useEffect, useState } from "react";
import "./resourcecenter.scss";
import Header from "../../component/header/header";
import Footer from "../../component/footer/footer";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import inhouse from "./../../assets/HomeCarousel.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const Resourcecenter = () => {
  const [blogs, setBlogs] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [searchBlog, setSearchBlog] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(
          `https://unipride-website-9xdk.onrender.com/blog/blogs`
        );
        if (response.data.success) {
          setBlogs(response.data.data);
        } else {
          console.log(response.data.message);
        }
      } catch (error) {
        console.log(error);
        // toast.error(`${error.message}`, {
        //   position: "bottom-right",
        //   autoClose: 8000,
        //   pauseOnHover: true,
        //   draggable: true,
        //   theme: "dark",
        // });
      }
    };
    fetchBlogs();
  }, []);

  const search = async () => {
    if (searchField !== "") {
      setSearchPerformed(true);
      try {
        const { data } = await axios.post(
          `https://unipride-website-9xdk.onrender.com/blog/searchBlog`,
          {
            search: searchField,
          }
        );
        setSearchBlog(Array.isArray(data.data) ? data.data : []);
      } catch (error) {
        console.error(`Error searching blogs: ${error.message}`);
        setSearchBlog([]);
      }
    } else {
      setSearchBlog([]);
      setSearchPerformed(false);
    }
  };

  useEffect(() => {
    search(searchField);
  }, [searchField]);

  useEffect(() => {
    if (blogs.length > 0) {
      setIsLoading(false);
    }
  }, [blogs]);

  console.log(isLoading);

  return (
    <>
      <div className="resourcecenter">
        <div className="resource-hero-img-section">
          <Header />
          <div className="resource-main-hero-content-section">
            <h1 className="resource-content">Resource Center</h1>
          </div>
        </div>
        <div className="search">
          <div className="search-container">
            <div className="row">
              <div className="col-12 col-sm-3 search-text">
                <h3>Search Blogs</h3>
              </div>
              <div className="col-sm-9 col-12 d-flex align-items-center">
                <div className="forminput">
                  <input
                    type="text"
                    placeholder="Keywords..."
                    value={searchField}
                    onChange={(e) => setSearchField(e.target.value)}
                  />
                  <div className="icon">
                    <i className="bi bi-search"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-results row">
            {searchPerformed && searchBlog.length === 0 ? (
              <div className="no-results">
                <p>No results found.</p>
              </div>
            ) : (
              searchBlog.map((blog, index) => (
                <div
                  key={index}
                  className="blog-result col-lg-4 col-md-3  col-sm-6"
                >
                  <div className="column-gapping " key={blog._id}>
                    <div className="blog-card">
                      <div className="img-outer">
                        <img src={blog.image} alt={blog.title} />
                      </div>
                      <div className="info">
                        <h5>
                          {blog.title.length > 20
                            ? blog.title.substring(0, 20) + "..."
                            : blog.title}
                        </h5>
                        <p>{blog.content.substring(0, 35)}...</p>
                        <div className="readmore">
                          <Link to={`/singleResource/${blog._id}`}>
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        <div className="latest-posts">
          <div className="container-main">
            <div className="heading">
              <h5>
                <span>Latest</span> Posts
              </h5>
            </div>
            {isLoading == true ? (
              <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="sr-only"></span>
                </div>
              </div>
            ) : (
              <div className="posts">
                <OwlCarousel
                  className="owl-theme"
                  loop
                  margin={0}
                  nav
                  autoplayTimeout={2000}
                  responsive={{
                    0: {
                      items: 1,
                    },
                    600: {
                      items: 2,
                    },
                    1000: {
                      items: 3,
                    },
                  }}
                >
                  {blogs &&
                    blogs.map((blog) => (
                      <div className="column-gapping" key={blog._id}>
                        <div className="blog-card">
                          <div className="img-outer">
                            <img src={blog.image} alt={blog.title} />
                          </div>
                          <div className="info">
                            <h5>
                              {blog.title.length > 20
                                ? blog.title.substring(0, 20) + "..."
                                : blog.title}
                            </h5>
                            <p>
                              {blog.content.length > 10
                                ? blog.content.substring(0, 35) + "..."
                                : blog.content}
                            </p>
                            <div className="readmore">
                              <Link to={`/singleResource/${blog._id}`}>
                                Read More
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </OwlCarousel>
              </div>
            )}
          </div>
        </div>
        <div className="all-posts">
          <div className="container-main">
            <div className="heading">
              <h5>All Posts</h5>
            </div>
            <div className="posts">
              <div className="row">
                {blogs &&
                  blogs.map((blog) => (
                    <div className="column-gapping col-md-4 col-sm-6">
                      <div className="blog-card">
                        <div className="img-outer">
                          <img src={blog.image} alt={blog.title} />
                        </div>
                        <div className="info">
                          <h5>
                            {blog.title.length > 20
                              ? blog.title.substring(0, 20) + "..."
                              : blog.title}
                          </h5>
                          <p>{blog.content.substring(0, 35)}...</p>
                          <div className="readmore">
                            <Link to={`/singleResource/${blog._id}`}>
                              Read More
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default Resourcecenter;
